import {ProgressSpinner} from 'primereact/progressspinner'
import {useEffect, useRef, useState} from 'react'
import text from '../../data/text.json'
import Site from '../../api/site'
import {Controller, useForm} from 'react-hook-form'
import {InputText} from 'primereact/inputtext'
import {InputTextarea} from 'primereact/inputtextarea'
import {Button} from 'primereact/button'
import {Card} from 'primereact/card'
import autoAnimate from '@formkit/auto-animate'
import {ReactSortable} from 'react-sortablejs'

const Carousel = () => {
    const [isFetching, setIsFetching] = useState(true)
    const lang = 'az'
    const {control, handleSubmit, reset} = useForm()
    const [loader, setLoader] = useState(false)
    const [file, setFile] = useState(null)
    const [carousel, setCarousel] = useState([])
    const containerRef = useRef()
    const [delLoader, setDelLoader] = useState(false)
    const fetchData = async() => {
        setIsFetching(true)
        const data = await Site.getCarousel()
        setCarousel(data?.reverse())
        setIsFetching(false)
    }

    const create = async(data) => {
        setLoader(true)
        const formData = new FormData()
        formData.append('title', data.title)
        formData.append('body', data.body)
        formData.append('photo', file)
        try {
            await Site.addCarousel(formData)
            reset({
                title: '',
                body: ''
            })
            fetchData()
            setFile(null)
        } catch (e) {
            console.log(e)
        } finally {
            setLoader(false)
        }
    }

    const deleteCarousel = async(id) => {
        setDelLoader(id)
        await Site.deleteCarousel(id)
        setDelLoader(false)
        fetchData()
    }

    const update = async() => {
        await Site.updateCarousel({
            sort: carousel.map(item => item?.id)
        })
    }

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        containerRef.current && autoAnimate(containerRef.current)
    }, [containerRef])

    return (
        <div className="profile-page">
            {isFetching ? (
                <div className="flex mt-5 justify-content-center align-items-center">
                    <ProgressSpinner/>
                </div>
            ) : (
                <div className="content w-full">
                    <p className="page-title">{text[lang].carousel_page_title}</p>
                    <div className="grid">
                        <div className="col-12 md:col-6">
                            <Card>
                                <form onSubmit={handleSubmit(create)}>
                                    <div className="grid">
                                        <div className="col-12 md:col-6">
                                            <label htmlFor="note">{text[lang].title}</label>
                                            <Controller render={({field: {value, onChange}}) => (
                                                <InputText
                                                    className="w-full"
                                                    value={value}
                                                    onChange={onChange}
                                                    id="name"
                                                    aria-describedby="name"
                                                    placeholder={text[lang].name}
                                                />
                                            )} name="title" control={control}/>
                                        </div>
                                    </div>
                                    <div className="grid">
                                        <div className="col-12 md:col-6">
                                            <label htmlFor="note">{text[lang].desc}</label>
                                            <Controller render={({field: {value, onChange}}) => (
                                                <InputTextarea
                                                    className="w-full"
                                                    value={value}
                                                    onChange={onChange}
                                                    placeholder={text[lang].desc}
                                                />
                                            )} name="body" control={control}/>
                                        </div>
                                    </div>
                                    <div className="grid">
                                        <div className="col-12 md:col-6">
                                            <label className="p-button p-button-danger">
                                                <input type="file" accept=".png,.jpeg,.jpg,.jfif"
                                                    onChange={e => setFile(e.target.files[0])}
                                                    style={{display: 'none'}}/>
                                                <i className="pi pi-file"/>
                                            </label>
                                        </div>
                                    </div>
                                    {file && (
                                        <div className="grid">
                                            <div className="col-12 md:col-6">
                                                <img width="100" height="100" style={{objectFit: 'cover'}}
                                                    src={URL.createObjectURL(file)} alt="32 Gozell"/>
                                            </div>
                                        </div>
                                    )}
                                    <div className="grid">
                                        <div className="col-12 md:col-6">
                                            <div className="flex justify-content-end">
                                                <Button className="p-button-danger d-flex align-items-center gap-1"
                                                    disabled={loader}>
                                                    {loader && <i className="pi pi-spin pi-spinner mr-1"/>}
                                                    {text[lang].save}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </Card>
                        </div>
                    </div>
                    <ReactSortable list={carousel} setList={setCarousel} className="grid" onChange={update}>
                        {carousel.map(item => (
                            <div style={{cursor: 'move'}} className="col-12 md:col-6" key={item.id}>
                                <Card title={item.title}>
                                    <div>
                                        <img style={{
                                            width: '100%',
                                            height: 300,
                                            objectFit: 'cover'
                                        }} src={process.env.REACT_APP_BASE_URL + '/' + item?.photo} alt={item.title}/>
                                    </div>
                                    <p className="m-0">
                                        {item.body}
                                    </p>
                                    <div className="mt-2 flex justify-content-end">
                                        <Button disabled={delLoader === item?.id} className="p-button-danger"
                                            onClick={() => deleteCarousel(item?.id)}>
                                            {delLoader === item?.id ? <i className="pi pi-spin pi-spinner mr-1"/> :
                                                <i className="pi pi-trash"/>}
                                        </Button>
                                    </div>
                                </Card>
                            </div>
                        ))}
                    </ReactSortable>
                </div>
            )}
        </div>
    )
}

export default Carousel
